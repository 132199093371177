import React, { useEffect, useContext, useState } from "react";
import TopNav from "../../routes/TopNav";
import $ from "jquery";
import logoIcon from "../../images/logo-icon.png";
import go from "../../images/go.svg";
import { useTranslation } from "react-i18next";
import { NavLink, Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Navigation from "./Navigation";
import Notifications from "react-notifications-menu";
import useNotification from "./useNotification";
import imgclose from "../../images/CrossIcon.svg";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../Componets/ModalLogout";
import { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AiTwotoneBell } from "react-icons/ai";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as moment from "moment";
import { Skeleton } from "@mui/material";
import SkeletonLoader from "../../helpers/SkeltonLoader";
import api from "../../apis/api";
import img_notif from "../../images/noti.svg";
import Dropdown from "react-bootstrap/Dropdown";
import NotificationComp from "./Notification";
import { ThemeContext } from "../Context";
import { baseUrl } from "../../apis/BaseUrl";
import SidebarNav from "../../routes/sidebarRouter";

export default function TopBar() {
  const userinfo = localStorage.getItem("ccfrUser");
  const { ToggleTheme, modeTheme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getClassroomsList = () => {
    console.log("scroll", paginationCursor);
    getCall();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const translation = useTranslation();
  const location = useLocation();
  const navigation = useNavigate();
  const [notif, setNotif] = useState([]);
  const [loaderlogout, setloaderlogout] = useState();
  const [togglemenu, setTogglemenu] = useState(false);
  const [modalopen, setmodalopen] = useState(false);
  const guest = localStorage.getItem("guest");
  const {
    getCall,
    loading,
    data,
    paginationCursor,
    clickHandler,
    count,
    notificationupdate,
    clearAll,
  } = useNotification();
  const activeStyle = { color: "red" };
  const Styleitem = { color: "white" };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const logoutHandler = () => {
    setmodalopen(true);
  };
  const logout = async () => {
    setloaderlogout(true);
    try {
      const response = await api.post("auth/removetoken", {
        email: localStorage.getItem("ccfr_email"),
        device_token: localStorage.getItem("NotificationToken"),
      });
      if (response) {
        localStorage.clear();
        setloaderlogout(false);
        window.location.href = "/";
      }

      // navigation("/");
    } catch (err) {
      localStorage.clear();
      setloaderlogout(false);
      // navigation("/");
      window.location.href = "/";
    }
  };
  const sidebarOpen = () => {
    // $("#mobNav").next(".hdr-main-nav").toggleClass("open");
    // $("#mobNav").toggleClass("active");
    $("body").toggleClass("fixed");
    setTogglemenu(!togglemenu)
  };

  return (
    <header class="ccfr-header" id="ccfr-header">
      <div class="hdr-main">
        <img src={logoIcon} alt="" class="logo-icon d-block d-md-none" />
        {TopNav?.map((item) => (
          <>
            {item?.path === "/donate" || item?.path === "/profile" ? null : (
              <h4 class="title-main d-block d-md-none my-2">
                {location.pathname === item?.path && translation.t(item.name)}
              </h4>
            )}
          </>
        ))}
        {location?.pathname === "/profile" && (
          <h4 class="title-main d-block d-md-none my-2">
            {translation.t("Profile")}
          </h4>
        )}
        {location?.pathname === "/contact" && (
          <h4 class="title-main d-block d-md-none my-2">
            {translation.t("Contact Us")}
          </h4>
        )}
        {location?.pathname === "/donate" && (
          <h4 class="title-main d-block d-md-none my-2">
            {translation.t("Make a Donation")}
          </h4>
        )}
        {isMobile && (
          // <li>
          <a href="#">
            <img src={go} alt="" width="21" onClick={logoutHandler} />
          </a>
          // </li>
        )}
        {isMobile && <NotificationComp />}
        <button id="mobNav" class="mob-menu">
          <div className={togglemenu ? "mobilemenu active" : "mobilemenu"} onClick={sidebarOpen}>
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </button>
        <div className="">
                <a>
                  <img src={go} alt="" width="21" onClick={logoutHandler} />
                </a>
              </div>

        <nav className={togglemenu ? "hdr-main-nav open" : "hdr-main-nav"}>
          {isMobile ? (
            <>
              <Navigation sidebarOpen={sidebarOpen} />
            </>
          ) : (
            <ul class="menulist list-unstyled">
              {modeTheme === "dark" ? (
                <>
                  {TopNav?.map((item) => (
                    <>
                      {guest && item.name === "Member Card" ? null : (
                        <li className="d-md-block d-none">
                          <NavLink
                            to={item?.path}
                            style={({ isActive }) =>
                              isActive ? activeStyle : Styleitem
                            }
                          >
                            {translation.t(item.name)}
                          </NavLink>
                        </li>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {TopNav?.map((item) => (
                    <>
                      {guest && item.name === "Member Card" ? null : (
                        <li className="d-md-block d-none">
                          <NavLink
                            to={item?.path}
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }
                          >
                            {translation.t(item.name)}
                          </NavLink>
                        </li>
                      )}
                    </>
                  ))}
                </>
              )}
              {isMobile && (
                <li onClick={() => testWebShare({ text: baseUrl })}>
                  <span className="top-item">{translation.t("Share")}</span>
                </li>
              )}
              
              <Navigation sidebarOpen={sidebarOpen} istab />
            </ul>
          )}
        </nav>
        {!isMobile && <NotificationComp />}
      </div>
      <LogoutModal
        show={modalopen}
        onHide={() => setmodalopen(false)}
        logout={logout}
        loaderlogout={loaderlogout}
      />
    </header>
  );
}
async function testWebShare({ text, files: filesUrl }) {
  // Test compatibility
  if (navigator.share === undefined) {
    // handleShareError("Unsupported share feature");
    return;
  }

  // Share content
  try {
    await navigator.share({ text });
  } catch (error) {
    // handleShareError(`Error sharing: ${error}`);
  }
}
