import React from "react";
import useNotification from "./useNotification";
import { AiTwotoneBell } from "react-icons/ai";
import Badge from "@mui/material/Badge";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
export default function NotificationComp() {
  const userinfo = localStorage.getItem("ccfrUser");
  const translation = useTranslation();
  const {
    getCall,
    loading,
    data,
    paginationCursor,
    clickHandler,
    count,
    notificationupdate,
    clearAll,
  } = useNotification();
  const getClassroomsList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getCall();
  };
  return (
    <>
      {userinfo && (
        <div class="notification-group">
          <a
            href="#"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Badge badgeContent={count} color="secondary">
              <AiTwotoneBell size={25} />
            </Badge>
          </a>
          {data?.length !== 0 && (
            <ul class="dropdown-menu dropdown-menu-end py-0">
              <li class="title bg-dark text-white p-3 d-flex justify-content-between">
                <h5 class="mb-0">Notifications</h5>
                <h6
                  class="mb-0"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={clearAll}
                >
                  <i>Clear all</i>
                </h6>
              </li>
              {data?.length !== 0 ? (
                <>
                  {data?.map((item) => {
                    return (
                      <li
                        key={item.notifications?.id}
                        class="items p-3"
                        onClick={() =>
                          notificationupdate(item.notifications?.id)
                        }
                      >
                        <a>
                          <div class="captions">
                            <h6 class="mb-1 me-2">
                              {item?.notifications?.ntf_head}
                            </h6>
                          </div>
                          <p class="mb-0 name-wrap">
                            {item?.notifications?.ntf_body}
                          </p>
                        </a>
                        <div className="mt-2 d-flex justify-content-end">
                          <p class="mb-1">
                            {moment(item?.created_at).format(
                              "DD/MM/YYYY hh:mm:ss"
                            )}
                          </p>
                        </div>
                      </li>
                    );
                  })}
                  {paginationCursor && (
                    <li
                      className="d-flex justify-content-center"
                      onClick={(e) => getClassroomsList(e)}
                      style={{
                        color: "red",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {loading ? (
                        <>
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </>
                      ) : (
                        <i>View more</i>
                      )}
                    </li>
                  )}
                </>
              ) : (
                <li className="d-flex justify-content-center">
                  <span> {translation.t("No data found")}</span>
                </li>
              )}
            </ul>
          )}
        </div>
      )}
    </>
  );
}
