import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../container/Context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

function LogoutModal(props) {
  const translation = useTranslation();
  const { modeTheme } = useContext(ThemeContext);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className={
        modeTheme === "dark" ? "special_modal-black" : "special_modal-white"
      }
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{"CCFR"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{translation.t("Do you want to Logout?")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{translation.t("Close")}</Button>
        <Button
          style={{ backgroundColor: "#D8D8D8", color: "black" }}
          onClick={props.logout}
        >
          {props?.loaderlogout ? (
            <div class="spinner-border text-danger" role="status">
              <span class="sr-only"></span>
            </div>
          ) : (
            translation.t("okay")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default LogoutModal;
