import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { toast } from "react-toastify";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};

firebase.initializeApp(firebaseConfig);

let messaging = new Promise((rej, res) => {});
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
} else {
  // dummy methods on messaging object
  messaging.getToken = () => {};
  messaging.onMessage = () => {};
}

const publicKey = process.env.REACT_APP_FireBase_publicKey;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    // toast.info("Please grant notification permission");
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      console.log(payload);
      resolve(payload);
    });
  });
