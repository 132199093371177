import axios from "axios";
export const PORTAL_API_URL = process.env.REACT_APP_API;
//export const PORTAL_API_URL = "http://localhost:3000";
//export const PORTAL_API_URL ="https://8c48-122-172-85-180.in.ngrok.io";
let token = "";
if (typeof window !== "undefined") {
  token = localStorage.getItem("ccfr_access_token");
}
export default axios.create({
  baseURL: `${PORTAL_API_URL}`,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});
