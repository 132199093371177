import React, { useState, useContext, useEffect } from "react";
import SidebarNav from "../../routes/sidebarRouter";
import $ from "jquery";
import { ThemeContext } from "../Context";
import { useTranslation } from "react-i18next";
import MyVerticallyCenteredModal from "../../Componets/Modal";
import { RWebShare } from "react-web-share";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import go from "../../images/go.svg";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../Componets/ModalLogout";
import api from "../../apis/api";
import FormDialog from "../../Componets/LogoutModal";
import { baseUrl } from "../../apis/BaseUrl";

export default function Navigation({ sidebarOpen ,istab}) {
  const navigation = useNavigate();
  const { ToggleTheme, modeTheme } = useContext(ThemeContext);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [modalopen, setmodalopen] = useState(false);
  const [modallogoutopen, setmodallogoutopen] = useState(false);
  const [modalcontent, setmodalcontent] = useState("");
  const { i18n } = useTranslation();
  const [loaderlogout, setloaderlogout] = useState();
  const translation = useTranslation();
  const guest = localStorage.getItem("guest");
  const lang = localStorage.getItem("selectedLanguage");
  const activeClassHandler = () => {
    $("a").addClass("active");
  };
  useEffect(() => {
    if (selectedLanguage !== undefined) {
      i18n.changeLanguage(selectedLanguage);

      localStorage.setItem("selectedLanguage", selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (lang === null) {
      setSelectedLanguage("en");
    }
    if (lang !== undefined && lang !== null) {
      setSelectedLanguage(lang);
    } else {
      setSelectedLanguage("en");
    }
  }, [lang]);

  const activeStyle = { color: "red" };
  const redirectToNew = (name) => {
    if (name === "Store") {
      setmodalcontent("https://shop.firearmrights.ca/");
    }
    if (name === "About Us") {
      setmodalcontent("https://firearmrights.ca/mission-and-values/");
    }
    if (isMobile) {
      sidebarOpen();
    }
    //
    setmodalopen(true);
  };
  const logoutHandler = () => {
    setmodallogoutopen(true);
  };
  const logout = async () => {
    setloaderlogout(true);
    try {
      const response = await api.post("auth/removetoken", {
        email: localStorage.getItem("ccfr_email"),
        device_token: localStorage.getItem("NotificationToken"),
      });
      localStorage.clear();
      setloaderlogout(false);
      // navigation("/");
      window.location.href = "/";
    } catch (err) {
      localStorage.clear();
      setloaderlogout(false);
      // navigation("/");
      window.location.href = "/";
    }
  };
  const languageHandler = (ee) => {
    setSelectedLanguage(ee);
    sidebarOpen();
  };
  const onChangeHandler = (event) => {
    ToggleTheme(event);
    sidebarOpen();
  };

  return (
    <>{istab?  <>{SidebarNav?.map((item) => (
      <>
        <li class="sidemenu-items d-md-none">
          {item?.name === "Language" ? (
            <div class="select-lang">
              <span>{translation.t("Language")}</span>
              <div class="language">
                <span
                  class={selectedLanguage === "en" ? "active" : null}
                  onClick={() => languageHandler("en")}
                >
                  ENG
                </span>
                <span
                  class={selectedLanguage === "fr" ? "active" : null}
                  onClick={() => languageHandler("fr")}
                >
                  FR
                </span>
              </div>
            </div>
          ) : (
            <>
              {item?.name === "Dark Mode" ? (
                <div class="theme-mode">
                  <span>{translation.t("Dark Mode")}</span>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => onChangeHandler(e)}
                      checked={modeTheme === "dark" ? true : false}
                    />
                    <span class="slider"></span>
                  </label>
                </div>
              ) : (
                <>
                  {item?.name === "Store" || item?.name === "About Us" ? (
                    // <a href={item?.path} target="_blank">
                    //   <span className="top-item">
                    //     {translation.t(item.name)}
                    //   </span>
                    // </a>

                    <span
                      className="top-item"
                      onClick={() => redirectToNew(item?.name)}
                      style={{ cursor: "pointer" }}
                    >
                      {translation.t(item.name)}
                    </span>
                  ) : (
                    <>
                      {item?.name === "Profile" && guest ? null : (
                        <NavLink
                          className="top-item"
                          to={item?.path}
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span onClick={sidebarOpen}>
                            {translation.t(item.name)}
                          </span>
                        </NavLink>
                      )}

                      {/* <a href={item?.
                      {/* <a href={item?.path}>
                        <span className="top-item">
                          {translation.t(item.name)}
                        </span>
                      </a> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </li>
      </>
    ))}

    {!isMobile && (
      <li className="sidemenu-items d-md-none">
        <a href="/contest">
          <span className="top-item">
            {translation.t("Contest Fundraiser")}
          </span>
        </a>
      </li>
    )}

    <li className="sidemenu-items d-md-none" style={{ cursor: "pointer" }}>
      <RWebShare
        data={{
          text: "Canadian Coalition for Firearm Rights",
          url: baseUrl,
          title: "CCFR",
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <span>{translation.t("Share")}</span>
      </RWebShare>
    </li>
    {/* {isMobile && (
      <li>
        <a href="#">
          <img src={go} alt="" width="21" onClick={logoutHandler} />
        </a>
      </li>
    )} */}
    <MyVerticallyCenteredModal
      show={modalopen}
      modalcontent={modalcontent}
      onHide={() => setmodalopen(false)}
    />
    <FormDialog
      show={modallogoutopen}
      onHide={() => setmodallogoutopen(false)}
      logout={logout}
      loaderlogout={loaderlogout}
    /></>:
    <ul class="list-unstyled">
      {SidebarNav?.map((item) => (
        <>
          <li class="sidemenu-items">
            {item?.name === "Language" ? (
              <div class="select-lang">
                <span>{translation.t("Language")}</span>
                <div class="language">
                  <span
                    class={selectedLanguage === "en" ? "active" : null}
                    onClick={() => languageHandler("en")}
                  >
                    ENG
                  </span>
                  <span
                    class={selectedLanguage === "fr" ? "active" : null}
                    onClick={() => languageHandler("fr")}
                  >
                    FR
                  </span>
                </div>
              </div>
            ) : (
              <>
                {item?.name === "Dark Mode" ? (
                  <div class="theme-mode">
                    <span>{translation.t("Dark Mode")}</span>
                    <label class="switch">
                      <input
                        type="checkbox"
                        onChange={(e) => onChangeHandler(e)}
                        checked={modeTheme === "dark" ? true : false}
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                ) : (
                  <>
                    {item?.name === "Store" || item?.name === "About Us" ? (
                      // <a href={item?.path} target="_blank">
                      //   <span className="top-item">
                      //     {translation.t(item.name)}
                      //   </span>
                      // </a>

                      <span
                        className="top-item"
                        onClick={() => redirectToNew(item?.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {translation.t(item.name)}
                      </span>
                    ) : (
                      <>
                        {item?.name === "Profile" && guest ? null : (
                          <NavLink
                            className="top-item"
                            to={item?.path}
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }
                          >
                            <span onClick={sidebarOpen}>
                              {translation.t(item.name)}
                            </span>
                          </NavLink>
                        )}

                        {/* <a href={item?.
                        {/* <a href={item?.path}>
                          <span className="top-item">
                            {translation.t(item.name)}
                          </span>
                        </a> */}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </li>
        </>
      ))}

      {!isMobile && (
        <li className="sidemenu-items">
          <a href="/contest">
            <span className="top-item">
              {translation.t("Contest Fundraiser")}
            </span>
          </a>
        </li>
      )}

      <li className="sidemenu-items" style={{ cursor: "pointer" }}>
        <RWebShare
          data={{
            text: "Canadian Coalition for Firearm Rights",
            url: baseUrl,
            title: "CCFR",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <span>{translation.t("Share")}</span>
        </RWebShare>
      </li>
      {/* {isMobile && (
        <li>
          <a href="#">
            <img src={go} alt="" width="21" onClick={logoutHandler} />
          </a>
        </li>
      )} */}
      <MyVerticallyCenteredModal
        show={modalopen}
        modalcontent={modalcontent}
        onHide={() => setmodalopen(false)}
      />
      <FormDialog
        show={modallogoutopen}
        onHide={() => setmodallogoutopen(false)}
        logout={logout}
        loaderlogout={loaderlogout}
      />
    </ul>
}
    </>
  );
}
