import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeContext } from "../container/Context";
import { useTranslation } from "react-i18next";

export default function FormDialog({ show, onHide, logout, loaderlogout }) {
  const { modeTheme } = useContext(ThemeContext);
  const translation = useTranslation();
  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        PaperProps={{
          style: {
            backgroundColor: modeTheme === "dark" ? "#2C3639" : "white",
            color: modeTheme !== "dark" ? "black" : "white",
          },
        }}
      >
        <DialogTitle>{"CCFR"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: modeTheme !== "dark" ? "#2C3639" : "white",
            }}
          >
            {translation.t("Do you want to Logout?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide} style={{ color: "red" }}>
            {translation.t("Close")}
          </Button>
          <Button onClick={logout} style={{ color: "red" }}>
            {loaderlogout ? (
              <div class="spinner-border text-danger" role="status">
                <span class="sr-only"></span>
              </div>
            ) : (
              translation.t("okay")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
