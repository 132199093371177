import React, { useEffect, useState } from "react";
import api from "../../apis/api";

export default function useNotification() {
  const [data, setData] = useState([]);
  const [paginationCursor, setpaginationCursor] = useState(undefined);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("heree++++", paginationCursor);
  }, [paginationCursor]);

  const clickHandler = (data) => {
    // getCall({ type: data });
  };
  useEffect(() => {
    getCall();
  }, []);
  const clearAll = async () => {
    try {
      setLoading(true);
      const response = await api.put("/ntf/read");
      //   setData(response?.data || []);
      if (response) {
        try {
          setLoading(true);
          const response = await api.get("/ntf/listntf", {
            params: {
              notificationStatus: "false",
              paginationCursor: undefined,
            },
          });

          setpaginationCursor(response?.data?.data?.paginationCursor);
          setData(response?.data?.data?.results);
          // setData(total);
          setCount(response?.data?.data?.count);

          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const notificationupdate = async (id) => {
    console.log(id);
    try {
      setLoading(true);
      const response = await api.put("/ntf/update", { notification_id: id });
      //   setData(response?.data || []);
      if (response) {
        try {
          setLoading(true);
          const response = await api.get("/ntf/listntf", {
            params: {
              notificationStatus: "false",
              paginationCursor: undefined,
            },
          });

          setpaginationCursor(response?.data?.data?.paginationCursor);
          setData(response?.data?.data?.results);
          // setData(total);
          setCount(response?.data?.data?.count);

          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getCall = async () => {
    console.log("call", paginationCursor);
    console.log("data", data);
    try {
      setLoading(true);
      const response = await api.get("/ntf/listntf", {
        params: {
          notificationStatus: "false",
          paginationCursor: paginationCursor ? paginationCursor : undefined,
        },
      });
      console.log("get", response?.data?.data?.paginationCursor);
      setpaginationCursor(response?.data?.data?.paginationCursor);
      var total = data.concat(response?.data?.data?.results);
      setData(total);
      setCount(response?.data?.data?.count);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return {
    getCall,
    loading,
    data,
    paginationCursor,
    clickHandler,
    count,
    notificationupdate,
    clearAll,
  };
}
