import React, { Suspense} from "react";
import { Routes, Route } from "react-router-dom";
import RouteList from "../routes/RoutesList";
import SiteAuthorisation from "./SiteAuthorisation";
import Sidebar from "../container/Sidebar/Sidebar";
import Spinner from "../helpers/Spinner";
import { onMessageListener } from "../firebaseInit";
import Notification from "../Notification";
// import io from "socket.io-client";
// import { PORTAL_API_URL } from "../apis/api";
import { toast } from "react-toastify";

// let socket;
// console.log("ID IS:-", id)

const SiteContent = () => {

  // const userInfo1 = JSON.parse (localStorage.getItem("userData"))
  // const userInfo2= JSON.parse (localStorage.getItem("ccfrUser"))
 
  // const [ENDPOINT] = useState(PORTAL_API_URL)
  // const [messages, setMessages] = useState([]);
  // const [userId, setUserId] = useState(null);

  // onMessageListener()
  //   .then((payload) => {
  //     console.log(payload);
  //     toast.info(payload?.notification_body)
      
  //   })
  //   .catch((err) => console.log("failed: ", err));

  // SETTING SOCKET CONNECTION
//   useEffect(() => {
//     socket = io(ENDPOINT);

//     if(socket){
//       socket.emit("join", { id: Date.now() }, (error) => {
//         if (error) {
//           console.log("ERROR CONNECTING SOCKET", error)
//         }
//       });
//     }

//     if(userInfo1){
//       setUserId(userInfo1?.id)
//     }else{
//       setUserId(userInfo2?.id)
//     }
//     return () => {
//       if(socket){
//         console.log("SOCKET DISCONNECTED!")
//         socket.off();
//       }
//   }
//   }, [ENDPOINT, userInfo1, userInfo2?.id]);

//   // STORING MESSAGES
//   useEffect(() => {
//     socket.on(userId, (message) => {
//       toast.info(message?.notification_body)
//         setMessages([...messages, message]);
        
//     });
//     socket.on("connection_success", (message) => {
     
//       console.log("CONNECTION SUCCESS MESSAGE:-",message)
//   });
   
// // eslint-disable-next-line react-hooks/exhaustive-deps
// },[userId]);
// console.log("MESSAGE RECIEVED:-", messages)

  return (
    <Sidebar>
      <Suspense fallback={<Spinner />}>
        <Notification />
        <Routes>
          {RouteList &&
            RouteList.map(
              (route) =>
                route.component && (
                  <Route
                    key={route.name}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={
                      <SiteAuthorisation
                        name={route.name}
                        type={route.type}
                        key={route.name}
                      >
                        <route.component />
                      </SiteAuthorisation>
                    }
                  />
                )
            )}
        </Routes>
      </Suspense>
    </Sidebar>
  );
};

export default SiteContent;
