import React from "react";
import { Navigate } from "react-router-dom";

const FeedContainer = React.lazy(() =>
  import("../container/views/Feeds/Feeds")
);
const RadioContainer = React.lazy(() =>
  import("../container/views/Radio/Radio")
);
const CardContainer = React.lazy(() =>
  import("../container/views/MemberCard/Card")
);

// const PaymentMethod = React.lazy(() =>
//   import("../container/views/BecomeMember/PaymentMob")
// );
const PartnerDetails = React.lazy(() =>
  import("../container/views/MemberDiscount/PartnerDetails")
);
const Donate = React.lazy(() => import("../container/views/Donation/donation"));
const DonateContainer = React.lazy(() =>
  import("../container/views/Donation/donation")
);
const DonatePay = React.lazy(() =>
  import("../container/views/Donation/DonationPay")
);
const Profile = React.lazy(() => import("../container/views/Profile/Profile"));
//
const Notification = React.lazy(() =>
  import("../container/views/Notification/Notification")
);
const Contact = React.lazy(() => import("../container/views/Contact/contact"));
const Contest = React.lazy(() => import("../container/views/Contest/Contest"));
const SingleContent = React.lazy(() =>
  import("../container/views/Feeds/SingleContent")
);
const Password = React.lazy(() =>
  import("../container/views/Password/PasswordChange")
);
const Redirect = () => (
  <>
    <Navigate to="/" />
  </>
);
const Redirectadmin = () => {
  localStorage.removeItem("StoreToken");
  return (
    <>
      <Navigate to="/adminuserlogin" />
    </>
  );
};

const RouteList = [
  {
    path: "/feed",
    name: "Products",
    exact: true,
    component: FeedContainer,
  },
  {
    path: "/radio",
    name: "Products",
    exact: true,
    component: RadioContainer,
  },
  {
    path: "/card",
    name: "Card",
    exact: true,
    component: CardContainer,
  },
  // {
  //   path: "/payment-method",
  //   name: "PaymentMethod",
  //   exact: true,
  //   component: PaymentMethod,
  // },
  {
    path: "/partner/:id",
    name: "partnerDetails",
    exact: true,
    component: PartnerDetails,
  },
  {
    path: "/donate",
    name: "donate",
    exact: true,
    component: Donate,
  },
  {
    path: "/donate/:id",
    name: "donates",
    exact: true,
    component: DonateContainer,
  },
  {
    path: "/pay_donation",
    name: "donation pay",
    exact: true,
    component: DonatePay,
  },
  { path: "/profile", name: "profile", exact: true, component: Profile },
  {
    path: "/notification",
    name: "notification",
    exact: true,
    component: Notification,
  },
  {
    path: "/contact",
    name: "contact",
    exact: true,
    component: Contact,
  },
  {
    path: "/contest",
    name: "contest",
    exact: true,
    component: Contest,
  },
  {
    path: "/password",
    name: "password",
    exact: true,
    component: Password,
  },
  {
    path: "/latestnews-details/:id",
    name: "SingleContent",
    exact: true,
    component: SingleContent,
  },
];

export default RouteList;
