import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { ThemeContext } from "../container/Context";
export default function BtnModal(props) {
  const { modeTheme } = useContext(ThemeContext);
  return (
    <Button
      href={props?.href}
      target={props?.target}
      onClick={props?.onClick}
      style={{ color: "red" }}
    >
      {props?.label}
    </Button>
  );
}
