import { useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import api from "../apis/api";

const SiteAuthorisation = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.guest) {
      const token = localStorage.getItem("ccfr_access_token");
      const refreshToken = localStorage.getItem("ccfr_access_refresh_token");
      const ccfr_email = localStorage.getItem("ccfr_email");
      const renewToken = async (data) => {
        try {
          const response = await api.post("auth/refreshtoken", data);

          localStorage.setItem(
            "ccfr_access_token",
            response?.data?.data?.token
          );
        } catch (err) {
          localStorage.removeItem("ccfrUser");
          localStorage.removeItem("ccfr_access_token");
          localStorage.removeItem("ccfr_access_refresh_token");
          navigate("/");
        }
      };
      if (!token || token === undefined) {
        navigate("/");
      } else {
        renewToken({ email: ccfr_email, refreshToken: refreshToken });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { ...props.children };
};

export default memo(SiteAuthorisation);
