import React, { useEffect, useState } from "react";
import api from "../../../apis/api";
import { useParams } from "react-router-dom";

export default function useBottomSlider() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [latestData, setLatestData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCall(id);
  }, []);
  const clickHandler = (data) => {
    getCall({ type: data });
  };
  const getCall = async (data) => {
    try {
      setLoading(true);

      const response = await api.get("/event/user/list");
      //   setData(response?.data || []);
      setData(response?.data.data?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return { getCall, loading, data, latestData, clickHandler };
}
