import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useContext } from "react";
import { ThemeContext } from "../container/Context";
export default function SkeltonLoader() {
  const { modeTheme } = useContext(ThemeContext);
  return (
    <>
      <>
        <h1>
          <Skeleton baseColor={modeTheme === "dark" ? "black" : "#ebebeb"} />
        </h1>
        <Skeleton
          count={10}
          baseColor={modeTheme === "dark" ? "black" : "#ebebeb"}
        />
      </>
    </>
  );
}
