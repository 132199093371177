import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../container/Context";
import React, { useState, useContext } from "react";
import { isMobile } from "react-device-detect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BtnModal from "./BtnModal";
import { useTranslation } from "react-i18next";

function MyVerticallyCenteredModal(props) {
  const translation = useTranslation();
  const { modeTheme } = useContext(ThemeContext);
  return (
    <>
      {isMobile ? (
        <Dialog
          open={props?.show}
          onClose={props?.onHide}
          PaperProps={{
            style: {
              backgroundColor: modeTheme === "dark" ? "#2C3639" : "white",
              color: modeTheme !== "dark" ? "black" : "white",
            },
          }}
        >
          <DialogTitle> {translation.t("Do you want to proceed?")}</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                color: modeTheme !== "dark" ? "#2C3639" : "white",
              }}
            >
              <p>
                {translation.t("You will be redirect to")}{" "}
                <b>{props?.modalcontent}</b>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BtnModal onClick={props?.onHide} label={"Cancel"} />
            <BtnModal
              href={props?.modalcontent}
              target="_blank"
              style={{ backgroundColor: "#D8D8D8", color: "black" }}
              onClick={props?.onHide}
              label={"Ok"}
            />
          </DialogActions>
        </Dialog>
      ) : (
        <Modal
          {...props}
          size="s"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={
            modeTheme === "dark" ? "special_modal-black" : "special_modal-white"
          }
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {translation.t("Do you want to proceed?")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {translation.t("You will be redirect to")}{" "}
              <b>{props?.modalcontent}</b>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props?.onHide}>{translation.t("Close")}</Button>
            <Button
              href={props?.modalcontent}
              target="_blank"
              style={{ backgroundColor: "#D8D8D8", color: "black" }}
              onClick={props?.onHide}
            >
              {translation.t("okay")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default MyVerticallyCenteredModal;
