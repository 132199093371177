const guest = localStorage.guest;
let TopNav = [];
if (guest) {
  TopNav = [
    {
      name: "Feed",
      path: "/feed",
    },
    {
      name: "CCFR Radio",
      path: "/radio",
    },
    {
      name: "Donate",
      path: "/donate",
    },
  ];
} else {
  TopNav = [
    {
      name: "Feed",
      path: "/feed",
    },
    {
      name: "CCFR Radio",
      path: "/radio",
    },
    {
      name: "Member Card",
      path: "/card",
    },
    {
      name: "Donate",
      path: "/donate",
    },
  ];
}

export default TopNav;
