import React, { useContext, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SiteContent from "../Layout/SiteContent";
import { ThemeContext } from "../container/Context";

const Language = lazy(() => import("../container/views/Language/Language"));
const Login = lazy(() => import("../container/views/Login/Login"));
const ForgotPassword = lazy(() =>
  import("../container/views/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../container/views/RestPassword/ResetPassword"));

const LoginMobile = lazy(() => import("../container/views/Login/LoginMob"));
const Subscription = lazy(() =>
  import("../container/views/subscription/subscription")
);
const Payment = lazy(() => import("../container/views/BecomeMember/payment"));
const Account = lazy(() => import("../container/views/BecomeMember/Account"));
const AppRoutes = () => {
  const { modeTheme } = useContext(ThemeContext);
  return (
    <>
      <div className="main-section" id={modeTheme}>
        <div className="page-content">
          <Routes>
            <Route
              path="/"
              exact={true}
              name="language"
              element={<Language />}
            />
            <Route
              path="/login"
              exact={true}
              name="Login"
              element={<Login />}
            />
            <Route
              path="/loginform"
              exact={true}
              name="LoginMobile"
              element={<LoginMobile />}
            />
            <Route
              path="/forgotPassword"
              exact={true}
              name="forgotPassword"
              element={<ForgotPassword />}
            />
            <Route
              path="/reset-password/:token"
              exact={true}
              name="reset-password"
              element={<ResetPassword/>}
            />
            <Route
              path="/subscription"
              exact={true}
              name="subscription"
              element={<Subscription />}
            />
            <Route
              path="/payment"
              exact={true}
              name="payment"
              element={<Payment />}
            />
            <Route
              path="/account"
              exact={true}
              name="account"
              element={<Account />}
            />

            <Route path="*" element={<SiteContent />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default AppRoutes;
