const guest = localStorage.guest;
let SidebarNav = [];
if (guest) {
  SidebarNav = [
    
    {
      name: "Store",
      path: "https://shop.firearmrights.ca/",
    },
   
    {
      name: "Language",
      path: "/language",
    },
    {
      name: "Contact Us",
      path: "/contact",
    },
    {
      name: "About Us",
      path: "https://firearmrights.ca/mission-and-values/",
    },
    {
      name: "Dark Mode",
      path: "/dark",
    },
  ];
} else {
  SidebarNav = [
    {
      name: "Profile",
      path: "/profile",
    },
    {
      name: "Store",
      path: "https://shop.firearmrights.ca/",
    },
    {
      name: "Notification Settings",
      path: "/notification",
    },
    {
      name: "Language",
      path: "/language",
    },
    {
      name: "Contact Us",
      path: "/contact",
    },
    {
      name: "About Us",
      path: "https://firearmrights.ca/mission-and-values/",
    },
    {
      name: "Dark Mode",
      path: "/dark",
    },
  ];
}
export default SidebarNav;
