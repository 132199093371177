import React, { useEffect, useRef, useState } from "react";
import {
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
  BsFillCaretUpFill,
} from "react-icons/bs";
import useBottomSlider from "./useBottomSlider";
import arrow from "../../../images/up-arrow.svg";
import { useTranslation } from "react-i18next";

function BottomSlider(props) {
  const translation = useTranslation();
  const { setOpenSlider, openSlider } = props;
  const { data } = useBottomSlider();
  const [images, setimge] = useState([
    "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
  ]);
  const [description, setDescription] = useState(["demo"]);
  const [title, setTitle] = useState(["demo"]);
  const [linkData, setlinkdata] = useState(["/demo"]);
  useEffect(() => {
    const imgData = data?.map((item) => item?.image_link);
    const description = data?.map((item) =>
      item?.description ? item?.description : ""
    );
    const title = data?.map((item) => (item?.title ? item.title : ""));
    const link_s = data?.map((item) =>
      item?.event_link ? item?.event_link : "null"
    );
    setimge(imgData);
    setDescription(description);
    setTitle(title);
    setlinkdata(link_s);
  }, [data]);

  const [hideImage, setHideImage] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(images[currentImageIndex]);
  const [currentdescription, setCurrentDescription] = useState(
    description[currentImageIndex]
  );
  const [currentTitle, setCurrentTitle] = useState(title[currentImageIndex]);
  const [currentLinkdata, setCurrentLinkdata] = useState(
    linkData[currentImageIndex]
  );
  const sliderDuration = 3000;
  let sliderTimer = null;

  const bottomNavRef = useRef();

  useEffect(() => {
    window.addEventListener("mousedown", handleModal);
    return () => {
      window.removeEventListener("mousedown", handleModal);
    };
  }, []);

  const handleModal = (event) => {
    if (bottomNavRef.current.contains(event.target)) {
      return false;
    }
    setOpenSlider(false);
  };

  useEffect(() => {
    setCurrentImage(images[currentImageIndex]);
    setCurrentDescription(description[currentImageIndex]);
    setCurrentTitle(title[currentImageIndex]);
    setCurrentLinkdata(linkData[currentImageIndex]);
    if (currentImageIndex > images.length - 1) {
      setCurrentImageIndex(0);
    }
    if (currentImageIndex < 0) {
      setCurrentImageIndex(images.length - 1);
    }
  }, [currentImageIndex]);

  const clearSliderInterval = () => {
    if (sliderTimer) {
      clearInterval(sliderTimer);
    }
  };

  useEffect(() => {
    if (!openSlider) {
      setTimeout(() => {
        setHideImage(false);
      }, 500);
    } else {
      setHideImage(true);
    }
  }, [openSlider]);

  //   for auto slide
  useEffect(() => {
    clearSliderInterval();
    sliderTimer = setTimeout(() => {
      setCurrentImageIndex((prev) => {
        return prev + 1;
      });
    }, [sliderDuration]);
    return () => {
      clearSliderInterval();
    };
  }, [currentImageIndex]);

  return (
    <div className="bottom-nav-slider-main-container" ref={bottomNavRef}>
      <div class={data?.length !== 0 ? "up-content" : null}>
        {data?.length !== 0 && (
          <img
            src={arrow}
            alt=""
            width="20"
            className={
              openSlider ? "slider-icon-down" : "bottom-nav-slider-top-icon"
            }
            onClick={() =>
              setOpenSlider((prev) => {
                return !prev;
              })
            }
          />
        )}
      </div>
      <div
        className={openSlider ? "show-image" : "bottom-nav-image-slider-box"}
      >
        {hideImage && (
          <>
            <img className="bottom-nav-images" src={currentImage} alt="text" />

            <div className="bottom-nav-button-container">
              <BsFillCaretLeftFill
                fontSize="large"
                className="slider-buttons"
                onClick={() =>
                  setCurrentImageIndex((prev) => {
                    return prev - 1;
                  })
                }
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span variant="h4" component="h6" color="white">
                  <b>{currentTitle}</b>
                </span>
                <span color="grey">
                  <i>
                    <b>{currentdescription}</b>
                  </i>
                </span>

                <button
                  style={{ backgroundColor: "#db4b32" }}
                  variant="contained"
                  onClick={() =>
                    window.open(
                      currentLinkdata,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  {translation.t("View More")}
                </button>
              </div>
              <BsFillCaretRightFill
                fontSize="large"
                className="slider-buttons"
                onClick={() =>
                  setCurrentImageIndex((prev) => {
                    return prev + 1;
                  })
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BottomSlider;
