import React, { useState, useContext } from "react";
import "./BottomNavBar.css";
import arrow from "../../images/up-arrow.svg";
import feed from "../../images/feed-icon.svg";
import radio from "../../images//radio-icon.svg";
import membercard from "../../images/membercard-icon.svg";
import donate from "../../images/donate-icon.svg";
import BottomSlider from "./BottomSlider/BottomSlider";
import { useTranslation } from "react-i18next";
import { bottomNav, bottomNavWhite } from "../../routes/BottomNavList";
import { ThemeContext } from "../Context";
import { NavLink } from "react-router-dom";
import useBottomSlider from "./BottomSlider/useBottomSlider";
function BottomNavBar() {
  const [openSlider, setOpenSlider] = useState(false);
  const { ToggleTheme, modeTheme } = useContext(ThemeContext);
  const translation = useTranslation();
  const guest = localStorage.getItem("guest");
  const activeStyle = { color: "blue" };
  const { data } = useBottomSlider();
  return (
    <div className="bottom-nav-bar-container d-md-none d-block">
      <div
        className={
          openSlider ? "slider-opens" : "bottom-nav-bar-slider-container"
        }
      >
        <BottomSlider setOpenSlider={setOpenSlider} openSlider={openSlider} />
      </div>
      {/* {data?.length!=0&& } */}
      <div class="footer d-md-none d-block">
        {!openSlider && (
          <div class="up-content">
           {data?.length !==0 && <img
              src={arrow}
              alt=""
              width="20"
              onClick={() => setOpenSlider(true)}
            />}
          </div>
        )}
        <div class="footer-content">
          {modeTheme === "dark" ? (
            <>
              {bottomNavWhite?.map((item) => {
                if (guest == 1 && item.name == "Member Card") {
                  return null;
                } else {
                  return (
                    <div class="icon-box">
                      {/* <a href={item?.path}>
                        <img src={item?.icon} alt="" />
                        <span className="top-item">
                          {translation.t(item.name)}
                        </span>
                      </a> */}
                      <NavLink
                        to={item?.path}
                        className={({ isActive }) =>
                          isActive ? "back-wrapp" : null
                        }
                      >
                        <img src={item?.icon} alt="" />
                        <span className="top-item">
                          {translation.t(item.name)}
                        </span>
                      </NavLink>
                    </div>
                  );
                }
              })}
            </>
          ) : (
            <>
              {bottomNav?.map((item) => {
                if (guest == 1 && item.name == "Member Card") {
                  return null;
                } else {
                  return (
                    <div class="icon-box">
                      {/* <a href={item?.path}>
                        <img src={item?.icon} alt="" />
                        <span className="top-item">
                          {translation.t(item.name)}
                        </span>
                      </a> */}
                      <NavLink
                        to={item?.path}
                        className={({ isActive }) =>
                          isActive ? "back-wrapp" : null
                        }
                      >
                        <img src={item?.icon} alt="" />
                        <span className="top-item">
                          {translation.t(item.name)}
                        </span>
                      </NavLink>
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BottomNavBar;
