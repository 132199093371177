import React, { useContext, useState } from "react";
import logo from "../../images/logo.png";
import logoWhite from "../../images/logo-white.png";
import TopBar from "./TopBar";
import SocialMedia from "./SocialMedia";
import Navigation from "./Navigation";
import BottomNavBar from "../BottomNavBar/BottomNavBar";
import { ThemeContext } from "../Context";
import $ from "jquery";
const Sidebar = ({ children }) => {
  const { ToggleTheme, modeTheme } = useContext(ThemeContext);

  return (
    <div className="main-content">
      <div className="leftside-bar">
        <div className="sidebar-menu">
          <div className="logobox">
            <a href="/login">
              {modeTheme === "dark" ? (
                <img src={logoWhite} alt="" />
              ) : (
                <img src={logo} alt="" />
              )}
            </a>
          </div>
          <Navigation />
          {/* <SocialMedia /> */}
        </div>
      </div>
      <TopBar />

      <div class="content-area" >
        {children}
        <BottomNavBar />
      </div>
    </div>
  );
};

export default Sidebar;
