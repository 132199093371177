import React from "react";

export default function Spinner() {
  return (
    <div
      id="backdrop"
      style={{
        position: "fixed",
        width: "100%",
        height: "100vh",
       
      }}
    >
      {" "}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100vh" }}
      >
        <div class="spinner"></div>
      </div>
    </div>

    // <div className="spinner-border" role="status">
    //   <span className="sr-only"></span>
    // </div>
  );
}
