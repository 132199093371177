import feed from "../images/feed-icon.svg";
import radio from "../images//radio-icon.svg";
import membercard from "../images/membercard-icon.svg";
import donate from "../images/donate-icon.svg";
import feedwhite from "../images/feed-icon-white.png";
import radiowhite from "../images/radio-icon-white.png";
import membercardwhite from "../images/membercard-icon-white.png";
import donatewhite from "../images/donate-icon-white.png";

export const bottomNav = [
  { icon: feed, name: "Feed", path: "/feed" },
  { icon: radio, name: "CCFR Radio", path: "/radio" },
  { icon: membercard, name: "Member Card", path: "/card" },
  { icon: donate, name: "Donate", path: "/donate" },
];

export const bottomNavWhite = [
  { icon: feedwhite, name: "Feed", path: "/feed" },
  { icon: radiowhite, name: "CCFR Radio", path: "/radio" },
  { icon: membercardwhite, name: "Member Card", path: "/card" },
  { icon: donatewhite, name: "Donate", path: "/donate" },
];
