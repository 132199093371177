import { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import history from "./helpers/history";
import AppRoutes from "./routes/AppRoutes";
import "./css/styles.css";
import "./customstyle/index.css";
import { ThemeContext } from "./container/Context";
import Spinner from "./helpers/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {

  const [modeTheme, setThemeMode] = useState(localStorage.getItem("theme"));
  const ToggleTheme = () => {
    localStorage.setItem("theme", modeTheme === "light" ? "dark" : "light");
    setThemeMode((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ modeTheme, ToggleTheme }}>
      <ToastContainer />
      <BrowserRouter history={history}>
        <Suspense fallback={<Spinner />}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </ThemeContext.Provider>
  );
}

export default App;
